export const productAddToCart = {
  largeScreenClass: 'vf-button--medium vf-button--fullWidth',
  scrollToErrorOffset: 145,
  scrollToErrorOffsetWithTopStickyHeader: 179,
  activeClass: 'vf-button--primary',
  notifyClass: 'vf-button--primary',
  pdpUrlWithAttributes: false,
  modal: {
    loggedIn: 'loyaltyEnrollment',
    notLoggedIn: 'loyaltyEnrollment',
  },
  quickshopScrollToTopAfterAddToCart: true,
};
