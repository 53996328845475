export const staticCart = {
  closeIconSize: '0.875rem',
  discountNotification: {
    notificationPersistent: false,
    notificationType: 'info',
  },
  promotionMessage: {
    promotions: [],
  },
  deliveryMethodSelector: {
    cancelButtonColor: 'secondary',
  },
  panelCloseButtonSize: '1.5rem',
  promoCode: {},
  orderSummarySidebar: {},
  miniCart: {
    cartIcon: 'cart',
    buttons: {
      cart: 'tertiary',
    },
  },
  productCard: {
    imageWidth: 94,
    imageHeight: 117,
    removeIcon: 'trash',
    removeIconSize: '24px',
  },
  expressCheckout: {
    isPayPalVisible: true,
    isApplePayVisible: true,
  },
  savedForLaterProducts: {
    paginationCounter: 6,
  },
  showInformationalSidebar: false,
  giftOptionEnabled: false,
  hasGiftTopNotification: true,
  giftAppliedMessageIcon: {
    icon: 'info',
    color: 'black',
  },
  upsell: {
    color: 'secondary',
    buttonSize: 'sm',
  },
};
