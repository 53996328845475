




































































































































































































































































































































































































































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import {
  handlePreviewModeClientSide,
  transformSeoMetadata,
  useUserData,
} from '@/components/cms/cmsUtils';
import { usePdp } from '@vf/composables/src/useStaticLayout';
import { setPageTypeName } from '@/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
import { useAuthentication, useProduct } from '@vf/composables';
import useSignInToBuy from '@/components/smart/shared/composables/useSignInToBuy';
import { ProductInventoryState } from '@vf/api-client';

export default defineComponent({
  name: 'TheNorthFaceTemplate',
  components: {
    CmsDynamicSlot: () => import('@/components/cms/CmsDynamicSlot.vue'),
    VfShippingDestinations: () =>
      import('@/components/static/pdp/ShippingDestinations.vue'),
  },
  setup() {
    const { root } = useRootInstance();
    const { openModal } = useModal();
    const {
      allowApplePayOrderOnPDP,
      areMonetateExperiencesSetInCmsForTemplatizedPages,
      isBopis20Enabled,
      pdpKlarnaTextAboveAddToCartCta,
      pdpProductRecommendationsAboveReviews,
      pdpShowApplePayAsPrimaryCta,
      showEddOnPdp,
    } = useFeatureFlagsStore();

    const {
      contextKey,
      product,
      meta,
      dynamicContentBelow,
      dynamicSlots,
      monetateExperiencesPdp,
      addToCartBtnModalsProps,
      inventoryLoading,
      productColorsKey,
      klarnaEnabled,
      seoWidgetRecommendationsEnabled,
      onThirdPartyLoaded,
    } = usePdp(root, {
      handlePreviewModeClientSide,
      openModal,
      setPageTypeName,
      useUserData,
    });

    const { isIpaProsumer, isWranxProsumer } = useAuthentication(root);

    const {
      isAttributeOutOfStockStatus,
      checkNotifyMeEnabled,
      isSelectedProductOutOfStock,
      productColor,
      colorsOptions,
    } = useProduct(root, contextKey);

    const { getSignInToBuyState } = useSignInToBuy(root, contextKey);
    const userStore = useUserStore(root);

    const isNotifyMeEnabled = computed(checkNotifyMeEnabled);

    const isShippingDestinationsVisible = computed(() => {
      if (!product.value) return false;
      return (
        !isNotifyMeEnabled.value &&
        !isIpaProsumer.value &&
        !isWranxProsumer.value &&
        ![
          isSelectedProductOutOfStock.value,
          !productColor.value,
          isAttributeOutOfStockStatus('size', product.value.size?.value),
          isAttributeOutOfStockStatus('length', product.value.length?.value),
          product.value?.productInventoryState ===
            ProductInventoryState.SoldOut,
          getSignInToBuyState(product.value, userStore.loyaltyEnrolled),
        ].some(Boolean)
      );
    });

    const isApplePayEnabled = computed(
      () =>
        allowApplePayOrderOnPDP &&
        !isNotifyMeEnabled.value &&
        !getSignInToBuyState(product.value, userStore.loyaltyEnrolled)
    );

    return {
      addToCartBtnModalsProps,
      areMonetateExperiencesSetInCmsForTemplatizedPages,
      colorsOptions,
      contextKey,
      dynamicContentBelow,
      dynamicSlots,
      inventoryLoading,
      isApplePayEnabled,
      isBopis20Enabled,
      isShippingDestinationsVisible,
      klarnaEnabled,
      meta,
      monetateExperiencesPdp,
      onThirdPartyLoaded,
      pdpKlarnaTextAboveAddToCartCta,
      pdpProductRecommendationsAboveReviews,
      pdpShowApplePayAsPrimaryCta,
      product,
      productColorsKey,
      seoWidgetRecommendationsEnabled,
      showEddOnPdp,
    };
  },
  head() {
    return transformSeoMetadata(this);
  },
});
